label {
  color: #525A84;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.24px;
  min-height: 28px;
  margin-bottom: 5px;
  padding: 7px 8px;
}

.input-group-text-s {
  font-size: 12px !important;
  padding: 4px 4px !important;
}

// Select box
.css-olqui2-singleValue {
  color: hsl(0deg 5.42% 25.85%) !important;
}

.css-3iigni-container>div>div>div {
  font-size: 12px !important;
  padding: 3px !important;
}
.css-3iigni-container {
  margin-bottom: 5px;
}
.input-group .css-3iigni-container {
  margin-bottom: 0px;
}

.css-3iigni-container>div>div {
  padding: 0;
}

.css-3iigni-container>div {
  border: none;
  border-radius: 2px;
  min-height: 20px;
}

.css-1xc3v61-indicatorContainer {
  color: #6c7d94 !important;
  padding: 3px !important;
}

.css-16xfy0z-control {
  min-height: 22px !important;
  background: #C9CCDE !important;
  border-radius: 2px !important;
  padding: 1px;
}

.css-1fdsijx-ValueContainer {
  font-size: 12px !important;
  padding: 0px 9px;
}
.css-tj5bde-Svg {
  width: 15px !important;
  height: 15px !important;
}

.css-3iigni-container .css-16xfy0z-control .css-1xc3v61-indicatorContainer>svg {
  color: #697b91 !important;
}

.css-3iigni-container>div>div>span {
  display: none;
}
.css-1h01tm3-Input {
  height: 18px !important;
}
.css-b62m3t-container {
  margin-bottom: 5px;

  .css-qbdosj-Input .css-15lsz6c-indicatorContainer {
    padding: 0;
  }

  .css-13cymwt-control {
    background-color: #EDF0FF;
    .css-1xc3v61-indicatorContainer{
      padding: 3px;
    }
    .css-1xc3v61-indicatorContainer>svg {
      color: #697B91 !important;
    }
  }

  .css-1nmdiq5-menu {
    margin: 1px 0 0;
    border-radius: 2px;
    background: #FFF;
    box-shadow: 0px 4px 16px 0px rgba(63, 63, 83, 0.40);
    padding: 9px;
    overflow: hidden;

    >div>div {
      font-size: 12px !important;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.24px;
      border-radius: 4px;
      background-color: transparent;
      padding: 0 9px;
      color: #3F3F53 !important;

      &:hover {
        color: #FFF !important;
        background: linear-gradient(180deg, #647DFF 0%, #3E5DFF 100%);
      }
    }
  }

  >div {
    min-height: 20px;
    border: none;
    border-radius: 2px;
    background-color: #fff;

    &:hover {
      border: none;
    }

    >div {
      padding: 0;

      &.css-1fdsijx-ValueContainer {
        padding-left: 6px;
        
        .css-1dimb5e-singleValue {
          color: #000000;
          font-size: 12px !important;
          line-height: 14px;
          letter-spacing: 0.24px;          
        }
        .css-1jqq78o-placeholder{
          font-weight: normal;
          color: #697B91;
          margin: 0;
        }
      }

      &.css-1hb7zxy-IndicatorsContainer>div {
        padding: 4px;
      }

      >div {
        padding: 3px !important;
        font-size: 12px !important;

        >svg {
          width: 15px;
          height: 15px;
          color: #3F3F53;
        }
      }

      >span {
        display: none;
      }
    }
  }
}

.input-group {
  column-gap: 5px;
  margin-bottom: 5px;

  .css-b62m3t-container,
  .form-control {
    margin-bottom: 0 !important;
  }

  .form-control {
    border-radius: 2px !important;
  }
}

// Date group
.date-group {
  border-radius: 2px;
  background-color: #EDF0FF;
  column-gap: 0;

  .react-datepicker-wrapper {
    width: 100%;
    flex: 1 1 auto;
    min-width: 0;
    position: relative;

    .form-control {
      padding: 7px 25px 7px 8px;
    }
  }

  .input-group-text {
    position: absolute;
    right: 0;
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 7px;
  }
}

// Input
input[type=text],
input[type=number] {
  &.form-control {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
    border: none;
    background-color: #EDF0FF;
    border-radius: 2px;
    padding: 7px 8px;
    margin-bottom: 5px;

    &::placeholder {
      color: #697B91; // Fallback for modern browsers

      /* Vendor prefixes for older browsers */
      &::-webkit-input-placeholder {
        color: #697B91;
      }

      &::-moz-placeholder {
        color: #697B91;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #697B91;
      }

      &:-moz-placeholder {
        color: #697B91;
        opacity: 1;
      }
    }

    &:focus {
      box-shadow: 0 0 0 1px #2684FF;
      background-color: #fff;
      font-weight: 500;
      color: #3F3F53;
    }
  }

  &:disabled {
    background: #C9CCDE;
    color: #4E536C;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

// Button style
.btn {
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.24px;
  border-radius: 2px;
  border: none;

  &.btn-primary {
    background: #3E5DFF;
  }

  &.btn-sm {
    padding: 7px 12px;
  }
}

.lable-white-space {
  white-space: nowrap;
}