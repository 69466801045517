.consting-table {
    > tbody > tr {
        &:nth-of-type(odd) > * {
          background-color: #f5f5fe;
        }
    }
    .thead, tbody, tfoot, tr, td, th{
        border-style: none;
    }
    > :not(caption)>*>* {
        box-shadow: none;
        background-color: transparent;
    }

    th,
    td {
        color: #3F3F53;
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.24px;
        padding: 4px;
        white-space: nowrap;
    }

    th {
        font-weight: 700;
    }

    td {
        font-weight: normal;

        input[type='text'],
        input[type='number'] {
            min-height: 28px;
            min-width: 28px;
            color: #646C95;
            font-size: 12px;
            font-weight: normal;
            line-height: 14px;
            letter-spacing: 0.24px;
            appearance: none;
            background: #EDF0FF;
            border-radius: 0;
            margin: 0;
            padding: 7px 8px;
            border-radius: 2px;
            z-index: 1;
        }
    }
}

.input-filed {
    padding-left: 0px;


}

.costing-search-button {
    margin-right: 10px;
}

.details-button {
    background-color: rgb(255, 254, 254) !important;
    color: #000 !important;
    text-align: left !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.24px !important;
    padding: 5px !important;
    border: 1px solid #9b9b9b !important;
    &:hover {
        color: #000 !important;
        box-shadow: none !important;
    }
}

.dropdown-menu.show{
    padding: 1px !important;
    box-shadow: none !important;
    color: #000 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    letter-spacing: 0.24px !important;
}

.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .show > .btn.dropdown-toggle:focus{
    box-shadow: none !important;
}

.bold-text {
    font-weight: 500;
    color: #000000;
}

.pagination-item {
    cursor: pointer;
}

.disabled {
    background-color: #d3d3d3 !important;
    cursor: not-allowed;
    color: #6c757d !important;
    pointer-events: none;
    height: max-content;
}