.services {
  > tbody {
    >tr {
      td {
        &:nth-child(1),
        &:nth-child(8),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(16),
        &:nth-child(18),
        &:nth-child(21),
        &:nth-child(23),
        &:nth-child(25) {
          text-align: center !important;
        }

        .date-group {
          border-radius: 2px;
          background-color: #EDF0FF;
          column-gap: 0;

          .react-datepicker-wrapper {
            width: 100%;
            flex: 1 1 auto;
            min-width: 0;
            position: relative;

            .form-control {
              padding: 1px 25px 1px 8px !important;
            }
          }

          .input-group-text {
            position: absolute;
            right: 0;
            height: 100%;
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding: 7px;
          }
        }

        &:nth-child(1) {
          text-align: center;
        }
      }
    }
  }

  > tfoot {
    > tr {
      td {
        border-top: 1px solid #E4E4FA;

        &:nth-child(1) {
          border: none;
        }

        &:nth-child(2) {
          text-align: right;
        }
      }

      &:nth-child(1) {
        td {
          color: #3f3f53 !important;
          font-weight: 700;
          padding: 5px 5px 1px;
          border: none;
          text-align: right !important;

          &:before {
            top: 3px;
            border-top: 2px solid #d4d3f1;
          }

          &:nth-child(1) {
            &:before {
              border: none;
            }
          }
        }
      }
    }
  }
}

.custome-datePiker{
  width: 140px;
}

.custom-input-field{
  width: 93px;
  height: 20px;
}

#flexCheckDefault{
  margin-top: 8px;
}

.customDefaultInputField{
  text-align: right;
  vertical-align: middle;
}
.customDefaultLableField{
  text-align: left;
  vertical-align: middle;
}

.services > tbody > tr > td.input-border {
  width: 0px;
}

.clickableCellStyle {
  cursor: pointer;
}

.custom-service-summery{
  text-align: right !important;
  padding-right: 16px !important;
}

.customTotalWithoutVatService{
  padding-right: 16px !important;
}

.customTotalWithoutVatUnit{
  padding-right: 16px !important;
  text-align-last: end;
}

.customDefaultInputFieldUnitRate{
  text-align: right !important;
  padding-right: 16px !important;
}