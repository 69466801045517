.sidebar {
  &-brand {
    justify-content: space-between;
    column-gap: 12px;
    flex: 0 0 68px;
    padding: 0 20px;
    background: none;
  }

  &-narrow-unfoldable:not(:hover),
  &-narrow {
    .sidebar-toggler {
      position: relative;
    }
  }

  &-narrow-unfoldable:hover {
    .sidebar-toggler {
      .toggle-open {
        display: none;
      }
      .toggle-close {
        display: block;
      }
    }
  }

  &-narrow-unfoldable:not(:hover) {
    .sidebar-brand {
      justify-content: center !important;
      padding: 0 12px 0 20px;
    }

    .sidebar-toggler {
      .toggle-close {
        display: none;
      }
    }
  }

  &-fixed {
    width: 228px;
    &.sidebar-narrow-unfoldable:not(:hover),
    &.sidebar-narrow {
      width: 68px;
      padding-bottom: 0;
    }

    &.sidebar-narrow-unfoldable:not(:hover) {
      .nav-link {
        color: transparent;
      }
    }
  }

  ~ * {
    padding-left: 228px !important;
  }

  &-narrow-unfoldable:not(.sidebar-end) ~ * {
    padding-left: 68px !important;
  }

  &-toggler {
    width: 20px;
    padding: 0;
    flex: none;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    &::before {
      content: none;
    }

    .toggle-close {
      display: none;
    }
  }

  &-nav {
    padding-top: 12px;
    &.bottom-bar {
      justify-content: flex-end;
      padding-bottom: 27px;
    }
    .nav-link {
      font-size: 14px;
      line-height: 17px;
      padding-inline: 24px;
      padding-block: 15px;
      &:hover {
        background-color: transparent;
      }
    }
    .nav-icon {
      height: 18px;
      flex: 0 0 56px;
      margin-left: -12px;
      &.doc {
        height: 16.5px;
      }
      &.file {
        height: 21.6px;
      }
    }
  }
}
