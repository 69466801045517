.costing-sheet {
  padding: 0 !important;

  br {
    display: none;
  }

  .row > div > div > strong {
    display: block;
    margin-bottom: 9px;
  }

  strong {
    line-height: 14px;

    > u {
      color: #3f3f53;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.24px;
      text-decoration: none;
      margin-left: 10px;
    }
  }

  table {
    th,
    td {
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.24px;
      white-space: nowrap;
      border: none;
      padding: 1px 5px;
    }

    th {
      color: #3f3f53;
      font-weight: 700;
    }

    td {
      position: relative;
      color: #68677b !important;
      font-weight: 400;

      &::before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        z-index: 0;
        left: 0px;
      }

      input[type='text'],
      input[type='number'] {
        min-height: 17px;
        min-width: 32px;
        color: #3f3f53;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.24px;
        appearance: none;
        background: #f3f3ff;
        border-radius: 0;
        margin: 0;
        padding-top: 0px;
        padding-right: 15px;
        position: relative;
        z-index: 0;
        text-align: right;
        padding-bottom: 0px;
      }

      input[type='checkbox'] {
        border-radius: 0;
        border-color: #dbdbef;
        box-shadow: none;
        background-color: transparent;
        &:checked {
          background-color: #3e5dff;
          border-color: #3e5dff;
        }
      }

      .btn {
        display: flex;
        padding: 5px;
        background-color: transparent;
      }
    }

    > tbody > tr {
      &:nth-of-type(odd) > * {
        background-color: #f5f5fe;
      }

      > td {
        &.input-border {
          padding: 1px;
          background: #f3f3ff;
  
          &::before {
            border: 1px solid #dbdbef;
            height: 100%;
            top: 0px;
          }
        }
      }

      &:last-of-type > td.input-border:after {
        border-bottom: 0px solid #dbdbef;
      }
    }

    > :not(caption) > * > * {
      box-shadow: none;
    }
  }
}
