.accommodation {
  >tbody {
    >tr {
      td {

        &:nth-child(1),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(10),
        &:nth-child(12),
        &:nth-child(14),
        &:nth-child(16),
        &:nth-child(18),
        &:nth-child(21),
        &:nth-child(23),
        &:nth-child(25) {
          text-align: center;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          text-align: left;
        }

        .date-group {
          border-radius: 2px;
          background-color: #EDF0FF;
          column-gap: 0;
          width: 105px;

          .react-datepicker-wrapper {
            width: 100%;
            flex: 1 1 auto;
            min-width: 0;
            position: relative;

            .form-control {
              padding: 1px 20px 1px 8px !important;
              height: 15px;
            }
          }

          .input-group-text {
            position: absolute;
            right: 0;
            height: 100%;
            background-color: transparent;
            border: none;
            border-radius: 0;
            padding: 7px;
          }
        }


        &:nth-child(27) {
          vertical-align: middle;
        }
      }

    }
  }

  >tbody,
  >thead {

    th,
    td {

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        padding: 1px 10px;
      }

      &:nth-child(26) input[type='text'] {
        text-align: left;
        width: 85px;
      }
    }
  }

  >tfoot {
    >tr {
      td {
        text-align: right !important;
        position: relative;
        right: 10px;

        &:nth-child(2) {
          padding: 1px 20px;
          text-align: left !important;
        }
      }

      &:nth-child(1) {
        td {
          color: #3f3f53 !important;
          font-weight: 700;
          padding: 5px 5px 1px;

          &:before {
            top: 3px;
            border-top: 2px solid #d4d3f1;
          }

          &:after {
            bottom: 0;
            border-bottom: 1px solid #E4E4FA;
          }

          &:nth-child(1) {

            &:before,
            &:after {
              border: none;
            }
          }

          &:nth-child(2) {
            padding: 5px 20px 1px;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        td {
          &:after {
            bottom: 0;
            border-bottom: 1px solid #E4E4FA;
          }

          &:nth-child(1) {

            &:before,
            &:after {
              border: none;
            }
          }
        }
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9) {

        td,
        strong {
          font-weight: 500;
        }
      }

      &:nth-child(5) {
        td {

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            border-bottom: 1px solid #E4E4FA;
          }
        }
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        td {

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            border-bottom: 1px solid #E4E4FA;
          }
        }
      }
    }
  }
}

.clickableCellStyle {
  cursor: pointer;
}

.custome-datePiker {
  width: 140px;
}

.custome-datePiker-accommodation {
  width: 105px;
}

.custom-input-field {
  width: 93px;
  height: 20px;
  text-align: right;
  color: red;
}

#flexCheckDefault {
  margin-top: 8px;
}

.customDefaultInputField {
  text-align: center;
  vertical-align: middle;
}

.accommodation>tbody>tr>td.input-border {
  width: 0px;
}

.custom-m-table-width {
  width: 60px !important
}

.custom-table-width {
  width: 80px !important
}

.table-width {
  width: auto;
}

.column-width {
  min-width: 100px !important;

  .content-remove {
    .content-remove-button {
      display: flex;
      justify-content: end;

      .btn.btn-sm {
        padding: 2px 2px 3px 2px !important;
      }
    }
  }
}

.custom-remove-button {
  padding: 5px !important;
}

.col-md-accommodation {
  height: 20px;
  width: 12%;
}

.col-md-9 {
  width: 100%;
}

.col-md-width {
  width: 100%;
}

.width-remark {
  width: 135px;
}

.custom-without-vat{
  padding-right: 15px !important;
  text-align: right !important;
  padding-top: 1px !important;
}

.custom-input-right-padding{
  padding-right: 15px;
}

.custom-input-size-double-sharing{
  width: 100px !important;
}
.custom-input-size-double-room-cost{
  width: 84px !important;
}
.width-remark-amend {
  width: 200px;
  min-width: 200px;
}