.transportation {
  tbody>tr:nth-of-type(odd)>* {
    background-color: transparent !important;
  }

  tbody>tr td:nth-child(6),
  tbody>tr td:nth-child(7),
  tbody>tr td:nth-child(8) {
    padding: 2px 4px !important;
  }

  td {
    br {
      display: inherit;
    }

    input[type='text'],
    input[type='number'] {
      border: 1px solid #DBDBEF;
      text-align: left !important;
      line-height: 20px !important;
      z-index: 0 !important;
      font-size: 11px !important;
      min-width: none !important;
    }

    // Date group
    .date-group {
      border-radius: 2px;
      background-color: #EDF0FF;
      column-gap: 0;

      .react-datepicker-wrapper {
        width: 100%;
        flex: 1 1 auto;
        min-width: 0;
        position: relative;

        .form-control {
          padding: 1px 25px 1px 8px !important;
        }
      }

      .input-group-text {
        position: absolute;
        right: 0;
        height: 100%;
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 7px;
      }
    }

    .btn-color {
      background: #3E5DFF !important;
      color: white !important;
    }

    &:nth-child(1) {
      text-align: center;
    }
  }

  tfoot>tr {
    td {

      &:nth-child(5) {
        border: 1px solid #D5DAF2;
      }

      .btn {
        width: calc(100% - 32px);
        right: 0;
        height: 40px;
        top: -20px;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: #3E5DFF;
      }

    }
  }
}

.transportation-tbody-btn {
  tbody>tr {
    td {
      padding: 0 !important;

      .btn {
        width: calc(100% - 32px);
        height: 40px;
        top: -20px;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: #3E5DFF;
      }
    }
  }
}

.style-table {
  tbody {
    tr {
      td.px-1 {

        &:nth-child(2) {
          width: 10vw;
        }

        &:nth-child(3) {
          width: 15vw;
        }

        &:nth-child(4) {
          width: 16vw;
        }

        &:nth-child(5) {
          width: 11vw;
        }
      }
    }
  }
}

.transportation-table-margin {
  margin-bottom: 10px;
  margin-top: 10px;
}

.table-rows-margin {
  margin-bottom: 20px !important;
}

.vehicle-type-input-style {
  border: 1px solid #DBDBEF;
  width: 175px;
  .css-qbdosj-Input {
    margin: 0 !important;
    padding: 0 !important;
  }
  .css-166bipr-Input {
    margin: 0 !important;
    padding: 0 !important;
  }
  .css-19bb58m{
    margin: 0 !important;
    padding: 0 !important;
  }
 }

.group-label {
  font-weight: 700;
  font-size: 12px;
  color: #333333;
}