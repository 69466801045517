.booking {
  p {
    color: #3F3F53;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.24px;
    margin: 0;
  }

  .table {
    > :not(caption)>*>* {
      box-shadow: none;
      background-color: transparent;
    }

    th,
    td {
      color: #3F3F53;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.24px;
      padding: 4px;
      border: 1px solid #D5DAF2;
      vertical-align: middle;
      white-space: nowrap;
    }

    th {
      font-weight: 700;
    }

    td {
      font-weight: 500;

      input[type='text'],
      input[type='number'] {
        min-height: 28px;
        min-width: 28px;
        color: #646C95;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.24px;
        appearance: none;
        background: #EDF0FF;
        border-radius: 0;
        margin: 0;
        padding: 7px 8px;
        border-radius: 2px;
        z-index: 1;

        &:disabled {
          background: #C9CCDE;
          color: #4E536C;
        }
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  .btn {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .room {
    td {
      vertical-align: middle;
      border: none;
      border-bottom: 1px solid #D5DAF2;

      >div {
        display: flex;
        align-items: center;
        padding: 0;

        >label,
        >.css-b62m3t-container,
        input {
          margin: 0;
          flex-grow: 1;
        }

        >.css-b62m3t-container>div>div>div {
          padding: 0px !important;
          font-size: 12px !important;
        }

        >label {
          line-height: 20px;
        }

        >div {
          width: 15%;
        }
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: auto;
      }

      &:nth-child(1) {
        width: 10% !important;
        text-align: left;
        margin: 1px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 5%;
      }

      &:nth-child(1),
      &:nth-child(7) {
        padding: 0;
      }

      &:nth-child(4) {
        >div >div {
          >.css-b62m3t-container>div>div>div {
            padding: 0px !important;
            font-size: 12px !important;
          }
        }
      }

      &:nth-child(7) {
        width: 5%;
        padding: 10px;
        text-align: right;
      }

      .btn {
        background-color: transparent;
        padding: 0;
      }
    }
  }
}

.bold-text {
  font-weight: 500;
  color: #000000;
}

.amend-booking-button{
  margin-right: 25px;
}
.confirmed-booking-button{
  margin-right: 10px;
}
[data-tooltip] {
  position: relative;
  cursor: default;
}

[data-tooltip]::after {
  position: absolute;
  width: auto;
  z-index: 1;
  left: calc(66% - 70px);
  bottom: -48%;
  text-align: center;
  box-sizing: border-box;
  content: attr(data-tooltip);
  color: #000000; 
  border: 1px solid #1d1c1c;
  background-color: rgb(255, 255, 255);
  padding: 1px;
  border-radius: 10x;
  font-size: 11px;
  letter-spacing: 0.4px;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.1s, transform 30000ms;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 2px;
  font-weight: 400;
  padding: 3px 10px;
}

[data-tooltip]:hover::after {
  opacity: 1;
  visibility: visible;
  transition-delay: 1s;
}

[data-tooltip]:focus-within::after {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0s;
}

.custom-tooltip:hover::after {
  min-width: 168px !important;
}

.custom-tooltip-no-pax:hover::after {
  min-width: 160px !important;
  bottom: -117% !important;
}

.paxrange{
  display: flex;
  justify-content: space-between;
}

.custom-paxrage{
  width: 30%;
}