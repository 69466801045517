.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;;
  padding: 30px;
  z-index: 9999;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 1300px !important;

  &-container {
    background-color: #fff;
    border-radius: 4px;
    max-width: 1174px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-confirm-container {
    background-color: #fff;
    border-radius: 4px; 
    max-width: 50%; 
    max-height: 50%; 
    display: flex;
    overflow: auto; 
    padding: 30px;
  }

  &-result {
    display: flex;
    flex-direction: column;
    min-height: 345px;
    padding: 13px 18px;
    overflow-y: auto;
    max-height: 500px;
    border-right: 1px solid #E2E2F1;
    > p {
      color: #3F3F53;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.28px;
      margin: 0;
    }
    &-empty {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > p {
        color: #68677B;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.32px;
        margin: 18px 0 50px;
      }
    }
    &-details {
      p {
        color: #3F3F53;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
      }
      table {
        margin-bottom: 0;
        th, td {
          color: #656488;
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
          letter-spacing: 0.24px;
          border: none;
        }
        th {
          padding: 3px 2.5px;
          > strong {
            color: #3F3F53;
            font-weight: 600;
          }
        }
        td {
          padding: 2.5px;
          &:last-of-type {
            display: flex;
          }
          .badge-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            column-gap: 2px;
            row-gap: 2px;
          }
          span {
            display: inline-block;
            &.blue,
            &.green {
              font-size: 11px;
              font-weight: 400;
              line-height: 13px;
              letter-spacing: 0.22px;
              padding: 3px 6px;
              border-radius: 2px;
            }
            &.blue {
              color: #3E5DFF;
              background: #EAEDFF;
            }
            &.green {
              color: #15CB53;
              background: #E2FFEC;
            }
          }
          .btn {
            display: flex;
            vertical-align: middle;
            border-radius: 2px;
            background: #3E5DFF;
            padding: 5px;
          }
        }
      }
    }
  }

  &-summary {
    padding: 60px 7px 60px 20px;
    p, span {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.24px;
    }
    p {
      margin: 0;
      color: #3F3F53;
      font-weight: 600;
    }
    span {
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: #656488;
      font-weight: 500;
    }
    > .row {
      margin-top: 16px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      &:nth-of-type(2) {
        margin-top: 6px;
      }
    }
  }

  &-service {
    display: block;
    margin-left: 10px;
  }

  .btn-primary {
    display: flex;
    align-items: center;
    column-gap: 8.5px;
    font-weight: 500;
    margin-top: 21px;
    border-radius: 50px;
    padding: 6px 14px;
    background: #636DA5 !important;
  }

  .btn-yes {
    display: flex;
    align-items: center;
    column-gap: 8.5px;
    font-weight: 500;
    margin-top: 21px;
    border-radius: 2px;
    padding: 6px 14px;
    background: #3E5DFF !important;
  }
  .btn-no {
    display: flex;
    align-items: center;
    column-gap: 8.5px;
    font-weight: 500;
    margin-top: 21px;
    border-radius: 2px;
    padding: 6px 14px;
    background: #e74c3c !important;
  }
}

.row-bg-color{
  &:nth-of-type(even) > * {
    background-color: #f5f5fe;
  }
}
.pb-custom {
  padding-bottom: 5px !important;;
}