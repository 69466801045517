.slab {
  .row {
    .table {
      margin: 0;

      th {
        padding: 2px 0;

        &:nth-child(1) {
          width: auto;
        }

        &:nth-child(2) {
          width: 25%;
        }
      }
    }
  }

  input[type=text],
  input[type=checkbox],
  input[type=number],
  .css-b62m3t-container {
    margin-bottom: 0;
  }

  .table {
    th,
    td {
      color: #3F3F53;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.24px;
      border: none;
      vertical-align: middle;
      padding: 8.5px 0;

      &.hide-border {
        border: none;

        &:before {
          border: none !important;
        }

        &:after {
          border-right: none !important;
          border-left: 1px solid #DBDBEF;
          left: 0 !important;
        }
      }

      >span.hidden {
        display: block;
        width: 100%;
        height: calc(100% - 1px);
        background-color: #D9D9EF;
        position: absolute;
        top: 1px;
      }
    }

    th:nth-child(1) {
      width: 25.5%;
    }

    .input-border {

      th,
      td {
        padding: 1px;
      }

      td {

        input[type=text],
        input[type=checkbox],
        input[type=number],
        .css-b62m3t-container {
          margin: 0 0 -1px;
          width: calc(100% - (-1px));
        }

        input[type=text],
        input[type=checkbox],
        input[type=number] {
          position: relative;
          z-index: 1;
          padding: 0 4px;
          border-radius: 0;
          text-align: center;
          min-height: 18px;
        }

        input[type=checkbox] {
          position: relative;
          z-index: 10 !important;
          padding: 0 4px;
          border-radius: 0;
          text-align: center;
          min-height: 18px;
        }


        .css-b62m3t-container {
          z-index: 2;

          >div {
            border-radius: 0;
            min-height: 18px;

            >div>div {
              margin: 0;

              >svg {
                width: 10px;
                height: 10px;
              }
            }
          }

          .css-1nmdiq5-menu {
            z-index: 2;
          }
        }
      }

      td:last-child {

        input[type=text],
        input[type=checkbox],
        input[type=number],
        .css-b62m3t-container {
          width: calc(100% - 0px);
        }
      }
    }

    .table-border {

      th,
      td {
        padding: 2px 0 0;
        height: 19px;
      }

      td {
        text-align: center;
      }
    }

    .table-border,
    .input-border {
      td {
        .row {
          .tbl-radio {
            z-index: 3 !important;
            padding-right: 1px;
          }

          .custom-tbl-radio{
            position: relative;
            left: 25px;
          }

          .btn.btn-sm {
            padding: 2px 2px 3px 2px !important;
          }

          .btn {
            line-height: 5px !important;
          }
        }

        position: relative;

        &::before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          z-index: 0;
          left: 0;
        }

        &::before {
          border: 1px solid #DBDBEF;
          height: 100%;
          top: 0px;
          border-bottom: 0;
          border-right: 0;
        }

        &:last-child {
          &:after {
            border-right: 1px solid #DBDBEF;
            right: 0px;
            top: 0;
            left: auto;
            height: 100%;
            width: 1px;
          }
        }
      }
    }

    .empty-line {
      td {
        border-top: 1px solid #DBDBEF;
       
      }
    }

    tr {
      &:nth-child(1) {

        th,
        td {
          padding: 0;
        }
      }

      &:last-child {

        &.table-border,
        &.input-border {
          td {
            border-bottom: 1px solid #DBDBEF;
          }
        }
      }
    }
  }

  .form-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 13px;

    &-group {
      display: flex;
      align-items: center;
      margin-right: 69px;
    }

    input {
      width: 160px;
      border: 1px solid #D5DAF2;
      background-color: transparent;
      border-radius: 0;
      height: 28px;

      &:disabled {
        width: 80px;
        background: #F5F5FE;
        border-left: none;
        text-align: center;
      }
    }

    .btn {
      height: 28px;
      margin-left: 10px;
    }
  }
}

.slab .table .input-border {
  th {
    &:nth-child(1) {
      margin-right: 260px;
      display: flex;
      width: 100%;
      height: 25px !important;
      align-items: center;
    }
     
  }

  .td-column-width {
    width: 185px;
    height: 24px !important;
  }
}

.slab .table .table-border td {
  width: 10%;
  text-align: end;
  padding-right: 37px !important;
}

.input-control-height {
  padding: 5px !important;
}

.table-width {
  width: 30%;
}

.slab .table .input-border td input[type=text],
.slab .table .input-border td input[type=checkbox],
.slab .table .input-border td input[type=number] {
  text-align: end;
  padding-right: 37px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.format-align-pax {  
  padding-right: 37px !important;
  text-align: end;
}

.format-align-vehicle-type{
  height: 10px !important;
}

.bold-title {
  font-weight: bold !important;
  font-size: 13px !important;
}