.header {
  min-height: 60px;
  padding: 0;
  border: none;
  .container-fluid:first-child {
    min-height: 60px;
  }

  &-nav {
    align-items: center;
    .nav-item {
      &.profile {
        font-size: 14px;
        font-weight: 500;
        margin-left: 23px;
        .nav-link {
          padding: 0;
        }
      }
      &.dropdown {
        margin-left: 12px;
        .nav-link {
          > svg {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .avatar {
    height: 36px;
    width: 36px;
    margin-right: 9px;
  }

  .nav {
    width: 100%;
    background: #EDF0FF;
    min-height: 36px;
    border: none;
    .nav-item,
    .nav-link {
      border: none;
    }

    .nav-link {
      font-size: 13px;
      line-height: 16px;
      border-radius: 0;
      color: #646C95;
      padding: 10px 22px 10px 20px;
      cursor: pointer;
      &.active {
        color: #FFF;
        font-weight: 600;
        background: #3E5DFF;
      }
    }
  }
}

.header-brand svg{
  display: none;
}
