.markup {
  p {
    color: #3F3F53;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.24px;
    margin-bottom: 3px;
    padding: 0;
  }

  table {
    td {
      padding: 1px;
      position: relative;

      &::before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        z-index: 0;
        left: 0px;
      }

      &:nth-child(2) {
        width: 20%;
        background: #F5F5FE;

        &::before {
          border: 1px solid #D5DAF2;
          height: 100%;
          top: 0px;
          border-bottom: 0;
        }
      }

      &:nth-child(1) {
        width: 80%;
      }

      input[type=text],
      input[type=number],
      .css-b62m3t-container {
        margin-bottom: 0;
        position: relative;
      }

      input[type=text],
      input[type=number] {
        background: #F5F5FE;
        height: 17px;
        border-radius: 0;
      }
    }

    tr:last-of-type > td:nth-child(2):after {
      border-bottom: 1px solid #D5DAF2;
      bottom: -1px;
    }
  }
}