.body {
  background: #1A2D91;
  padding-top: 12px;
  padding-bottom: 8px;

  .card {
    border: none;
    border-radius: 2px;
    margin-bottom: 5px !important;
    &-header {
      position: relative;
      border: none;
      background-color: transparent;
      color: #3F3F53;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.28px;
      padding: 4px 0 4px 10px;
      .btn {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 11px;
        font-weight: 500;
        line-height: 13px;
        letter-spacing: 0.22px;
        padding: 4px 17px 3px 5px;
        border-radius: 0px 2px;
        border: 0;
        svg {
          position: absolute;
          right: 5px;
          top: 9px;
        }
        .show {
          transform: scaleY(-1);
        }
      }
    }
    &-body {
      padding: 0 10px 5px;
      .row {
        margin-bottom: 0 !important;
      }
    }
  }
}